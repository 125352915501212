import { FC } from 'react';

import { Match } from '@common/clients/api';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';

interface Props {
    match: Match;
}

export const GroupByElement: FC<Props> = ({ match }) => {
    return (
        <li>
            <h3>
                {DateTimeUtil.format(match.kickOff, Format.DATE_LONG_OPTIONAL_YEAR)}
                {match.stageName ? ` (${match.stageName})` : ''}
            </h3>
        </li>
    );
};
