import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Match, ModuleScheduleSection, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { OddsBlock } from '@web/atoms/match/OddsBlock';
import { ScoreElement } from '@web/atoms/match/ScoreElement';
import { StatusElement } from '@web/atoms/match/StatusElement';
import { TeamElement } from '@web/atoms/match/TeamElement';
import { formatInternalRoute } from '@web/routing';

import styles from './MatchList.module.scss';

interface Props {
    match: Match;
    isOdd?: boolean;
    showOdds?: boolean;
    hideDisclaimer?: boolean;
    trackerName?: ModuleScheduleSection;
    route?: Route;
    isFirstOfPatch?: boolean;
    isLastOfPatch?: boolean;
}

export const MatchElement: FC<Props> = ({
    match,
    isOdd,
    showOdds,
    trackerName,
    route,
    isFirstOfPatch,
    isLastOfPatch,
}) => {
    const classes = [styles.match];
    const shouldShowOdds = showOdds && match.odds && match.odds.length > 0;

    if (!shouldShowOdds) match.odds = [];
    else classes.push(styles['has-odds']);

    // even/odd styling
    classes.push(isOdd ? styles.odd : styles.even);

    if (isFirstOfPatch) classes.push(styles['first-match']);
    if (isLastOfPatch) classes.push(styles['last-match']);

    const { context, platform } = useContextData();
    const __livescores = useTranslation('livescores').t;

    return (
        <li
            className={classes.join(' ')}
            onClick={(e) => {
                if (e.target instanceof HTMLAnchorElement && e.target.target === '_blank') {
                    return;
                }
                window.location.href = match.slug
                    ? formatInternalRoute(Route.Match, context.slug, __livescores, {
                          date: match.kickOffSlug,
                          slug: match.slug,
                      })
                    : formatInternalRoute(Route.MatchByID, context.slug, __livescores, {
                          matchID: match.id,
                      });
            }}
        >
            {/* TIMESTAMP */}
            <InternalLink
                className={styles.time}
                route={match.slug ? Route.Match : Route.MatchByID}
                query={match.slug ? { slug: match.slug, date: match.kickOffSlug } : { matchID: match.id }}
            >
                <StatusElement match={match} />
            </InternalLink>
            {/* SCORE */}
            <InternalLink
                className={styles.score}
                route={match.slug ? Route.Match : Route.MatchByID}
                query={match.slug ? { slug: match.slug, date: match.kickOffSlug } : { matchID: match.id }}
            >
                <ScoreElement match={match} />
            </InternalLink>
            {/* TEAMS */}
            <ul className={styles.teams}>
                {match.home ? (
                    <TeamElement
                        element="li"
                        team={match.home}
                        match={match}
                        useShortName={shouldShowOdds && platform.id !== PlatformID.VI ? undefined : false}
                    />
                ) : null}
                {match.away ? (
                    <TeamElement
                        element="li"
                        team={match.away}
                        match={match}
                        useShortName={shouldShowOdds && platform.id !== PlatformID.VI ? undefined : false}
                    />
                ) : null}
            </ul>
            {shouldShowOdds ? (
                <OddsBlock
                    match={match}
                    hasLimitedSpace={true}
                    hideDisclaimer={true}
                    trackerName={ModuleScheduleSection.ODDS_MATCHES}
                    section={trackerName}
                    route={route}
                />
            ) : null}
        </li>
    );
};
