import { FC } from 'react';

import { Match } from '@common/clients/api';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';

interface Props {
    match: Match;
}

export const DateElement: FC<Props> = ({ match }) => {
    return (
        <li>
            <h3>{DateTimeUtil.format(match.kickOff, Format.DATE_LONG_OPTIONAL_YEAR)}</h3>
        </li>
    );
};
